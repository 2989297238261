@import '/src/_metronic/assets/sass/init';
.avatar {
  position: sticky;
  top: 0;
}

.strong {
  color: #232536;
  font-weight: 500;
}

.collapse {
  :global(.ant-collapse) {
    background-color: white;
    border-color: $gray-300;
  }

  :global(.ant-collapse-content) {
    border-top: 1px solid #f9f9f9;
  }

  :global(.ant-collapse-content-box) {
    padding: 4px !important;
  }
}

.sectionCheckbox {
  border-radius: 6px;
  padding: 12px 16px;

  &:hover {
    background-color: $primary-light;
  }

  * {
    cursor: pointer;
  }
}

.select {
  :global(.ant-select-selection-item) {
    color: $gray-1000;
  }
}

.cardHeader {
  border-bottom: 1px solid var(--bs-gray-200);
  padding-bottom: 15px;
}

card {
  border: 1px solid var(--bs-gray-200);
}

.collapseHeader {
  color: #232536;
  font-weight: 500;
  font-size: 18px;
}

.collapse {
  background-color: white;
  border-color: var(--bs-gray-200);

  :global(.ant-collapse-item) {
    margin: 0 28px;
  }

  :global(.ant-collapse-header) {
    background-color: white;
    border-color: var(--bs-gray-200);
    padding: 12px 0 !important;
  }

  :global(.ant-collapse-content) {
    border-top: 1px solid var(--bs-gray-200);
    padding: 12px 0 24px 0 !important;
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}

.select {
  :global(.ant-select-selection-item) {
    color: var(--bs-gray-1000);
  }
}

.priorityBadge {
  width: 133px;
  height: 26px;
  font-size: 12px;
}

.sectionCheckbox {
  border-radius: 6px;
  padding: 12px 16px;

  &:hover {
    background-color: var(--bs-primary-light);
  }

  * {
    cursor: pointer;
  }
}

.emptySubject {
  min-height: 300px;
  display: grid;
  place-items: center;
}

//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors

//gray
$gray-200: #F1F1F4;

// Light colors
$light: #F1F1F2;
$light-inverse: #7E8299;

// Primary colors
$primary: #ffb800 ;
$primary-active: #f7b911d1;
$primary-light: #ffbb0012 ;
$primary-light-dark: #1c3238;
$primary-inverse: #ffffff;
$primary-placeholder: #99A1B7;
$primary-border: #DBDFE9;
$primary-modal: #000000B2;

// Secondary colors
$secondary: #ffbb0024;

// Alert
$alert-primary: #3E97FF;
$alert-primary-light: #EEF6FF;

// Success
$success: #2c9aff;
$success-active: #2884ef;
$success-light: #f1f4ff;
$success-light-dark: #212e48;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f14c41;
$danger-active: #dd4339;
$danger-light: #fff2f1;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Active
$active: #50CD89;
$active-light: #E8FFF3;
$active-inverse: #ffffff;

$input-border: #DBDFE9;

// Border Radiues
$border-radius: 0.65rem;
$border-radius-lg: 0.75rem;
$border-radius-xl: 1rem;

// Card
$card-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.03);
$card-box-shadow-dark: none;
$card-border-enabled: true;

$input-box-shadow: true !default;

$dark-describe-800: #252f4a;

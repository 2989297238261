@import '../../../styles/index.scss';
.trashExamDate {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.examSchedule {
  &:not(:last-child) {
    border-bottom: 1px dashed $input-border;
    padding-bottom: 24px;
    margin-bottom: 12px;
  }
}

.trashFiller {
  height: 21px;
  margin-bottom: 10px;
}

.trashIcon {
  flex-grow: 1;
}
